<template>
  <div id="misc">
    <v-card>
      <div class="page-title text-center pa-8">
        <h2 class="text-2xl font-weight-semibold text--primary d-flex align-center justify-center">
          <span class="me-2">Coming Soon</span>
        </h2>
        <v-btn
          outlined
          color="primary"
          to="/dashboard"
          class="mb-4 mt-4"
        >
          Back to home
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import {mdiAlert} from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiAlert,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/misc.scss';
</style>
